<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card" elevation="1">
        
        <v-card-title class="d-flex align-center justify-center py-md-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              height="30px"
              width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
            <h2 class="text-xl font-weight-semibold text--primary">Tagge Audit Center</h2>
          </router-link>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="input your email"
              hide-details
              class="mb-4"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              class="mt-12"
              :loading="isLogining"
              @click="login"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <v-divider class="my-4"></v-divider>

        <v-card-text>
          <p class="text-caption font-weight-semibold text--primary mb-2">
            The review committee consists of members of the Tagge community and is responsible for reviewing Web3 articles to ensure their quality and compliance.
          </p>
        </v-card-text>


      </v-card>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {  mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import {managerLogin} from '@/request/manager'


export default {
  components: {
  },
  data() {
    return {
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      isPasswordVisible:false,
      email: '',
      password: '', 
      appLogo: themeConfig.app.logo,
      isLogining: false,
    }
  },
  computed: {
  },
  watch: {
    '$store.state.authAccount': function(){
        if(this.$store.state.authAccount) {
            this.$router.push(this.$route.query.redirect || '/post')
        }
    }
  },
  mounted() {
    console.log(this.$store.state.authAccount)
    if(this.$store.state.authAccount) {
        this.$router.push(this.$route.query.redirect || '/post')
    }
  },
  methods: {
    async login() {
        //first check if the email and password is valid
        if(!this.email || !this.password) {
            this.$q.error('Please input your email and password')
            return
        }

        this.isLogining = true
        var resp = await managerLogin({
          email: this.email,
          password: this.password
        }).catch(err => {
            this.$q.error(err.message)
            this.isLogining = false
            return null
        });

        if(!resp) {
            return
        }

        if(resp.status != 0) {
            this.$q.error(resp.message)
            this.isLogining = false
            return
        }
        
        this.$store.commit('setAuthAccount', resp.data)
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
